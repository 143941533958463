//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'normal',
    },
    type: {
      type: String,
      default: 'default',
    },
    linkMore: {
      type: String,
      default: undefined,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    thumbStyle: {
      type: Object,
      default: undefined,
    },
  },

  computed: {
    showLinkMore() {
      return this.$device.isMobile && this.linkMore
    },
  },
}
