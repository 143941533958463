//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PasswordResetPage',

  data() {
    const { query } = this.$route

    return {
      key: query.key,
      formLoading: false,
      form: {
        password: '',
        password2: '',
      },
      formError: '',
    }
  },

  computed: {
    submitDisabled() {
      return this.formLoading
    },
  },

  methods: {
    async updatePassword() {
      if (this.form.password !== this.form.password2) {
        this.formError =
          '新しいパスワード（確認用） は「新しいパスワード」と同じ内容を入力してください'

        return
      }

      const form = {
        key: this.key,
        password: this.form.password,
      }

      this.formError = ''
      this.formLoading = true

      const res = await this.$axios.$post('/reset-password', form)

      this.formLoading = false

      if (res.status !== 200) {
        if (res.message) {
          this.formError = res.message
        }

        return
      }

      this.$toast({
        message: '更新しました',
      })

      this.$router.replace({
        path: '/login',
        query: {
          redirect: 'login',
        },
      })
    },
  },
}
