//
//
//
//
//
//
//
//
//
//
//
//
//

import Screen from '@/utils/mixins/Screen'

export default {
  mixins: [Screen],

  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    thumbStyle() {
      const style = {
        height: '212px',
      }

      if (this.$device.isMobile) {
        style.height = '236px'
      } else if (this.$screen.isSmDown) {
        style.height = 'auto'
      }

      return style
    },
  },
}
