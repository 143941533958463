//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    thumbStyle() {
      let height = '141px'

      if (this.$device.isMobile) {
        height = '235px'
      } else if (this.$device.isTablet) {
        height = '9vh'
      }

      const style = {
        height,
      }

      return style
    },
  },
}
