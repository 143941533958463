//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    thumbStyle() {
      return this.$device.isMobile ? {} : { width: '100px', height: '70px' }
    },
  },
}
