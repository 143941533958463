//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TitleMap from '@/utils/mixins/TitleMap'
import { getHead } from '@/utils'
import Pagination from '~/components/pagination/Pagination.vue'

export default {
  name: 'TopicPage',

  components: { Pagination },

  mixins: [TitleMap],

  layout: 'page',

  async asyncData({ $axios, params, query }) {
    const res = await $axios.$get('/article_list', {
      params: {
        post_type: params.name,
        page: query.page,
      },
    })

    const {
      article_list: {
        total: pageTotal,
        current_page: page,
        per_page: pageSize,
      },
    } = res.data

    let {
      article_list: { data: list },
      recommond_tag_list: tags,
    } = res.data

    if (list) {
      list = list.map((item) => {
        let author = ''

        if (item.guests?.length > 0) {
          const lastLen = item.guests.length - 1

          author = '監修コミマグアドバイザー：'

          for (let i = 0; i < item.guests.length; i++) {
            author += `${item.guests[i].name}${i < lastLen ? '、' : ''}`
          }
        }

        return {
          title: item.title,
          thumb: item.attachment?.file_url,
          summary: item.description,
          author,
          date: item.publish_date ? `公開日：${item.publish_date}` : '',
          link: `/article/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    if (tags) {
      tags = tags.map((item) => {
        return {
          label: item.name,
        }
      })
    }

    return {
      page,
      pageTotal,
      pageSize,
      list,
      tags,
    }
  },

  data() {
    const { query } = this.$route

    return {
      page: query.page || 1,
      pageTotal: 0,
      pageSize: 10,
      list: [],
      tags: [],
    }
  },

  head() {
    const { params } = this.$route
    const baseTitle = this.titleMap[params.name]?.metaTitle

    return getHead({
      title: `${baseTitle}｜コミマグ`,
      description: `${baseTitle}のページです。こんな時どうする？他塾はどうしてる？集客方法やモチベーションUPまでコミマグでは様々な明日の塾経営につながるコンテンツを掲載しています。`,
    })
  },

  computed: {
    titleSet() {
      return this.titleMap[this.$route.params.name] || {}
    },
    showPager() {
      return this.pageTotal > this.pageSize
    },
    thumbStyle() {
      return this.$device.isMobile ? {} : { width: '198px', height: '140px' }
    },
  },

  watchQuery: ['page'],

  methods: {
    toPage(page) {
      const { params } = this.$route

      this.$router.push({
        name: 'topic-name',
        query: {
          page,
        },
        params: {
          name: params.name,
        },
      })
    },
  },
}
