//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getHead } from '@/utils'

export default {
  name: 'SeminarDetailPage',

  layout: 'page',

  async asyncData({ $axios, params }) {
    const res = await $axios.$get(`/seminar/${params.id}`, {
      params: {
        id: params.id,
      },
    })

    const {
      title,
      attachment,
      description,
      recommended_for: recommended,
      time_and_place: timePlace,
      agenda,
      content,
    } = res.data

    let { guests } = res.data

    if (guests) {
      guests = guests.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.position,
          author: item.name,
          summary: item.description,
          link: `/adviser/${item.id}`,
        }
      })
    }

    return {
      title,
      cover: attachment?.file_url,
      description,
      recommended,
      timePlace,
      agenda,
      guests,
      content,
    }
  },

  data() {
    return {
      title: '',
      cover: '',
      description: '',
      recommended: '',
      timePlace: '',
      agenda: '',
      guests: [],
      content: undefined,
    }
  },

  head() {
    return getHead({
      title: `${this.title}｜セミナー｜コミマグ`,
      description: this.description,
      image: this.cover,
    })
  },
}
