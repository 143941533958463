//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    status() {
      return this.error.statusCode || this.error.status
    },
    title() {
      switch (this.status) {
        case 404:
          return '404 Not Found'

        default:
          return 'Error'
      }
    },
  },
  mounted() {
    const { status, error } = this
    if (status >= 500 && status < 600) {
      this.$sentry.captureException(error)
    }
  },
}
