//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LoginPage',

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },

  data() {
    return {
      form: {
        email: '',
        password: '',
        isSave: false,
      },
      formLoading: false,
    }
  },

  computed: {
    submitDisabled() {
      return this.formLoading || !this.form.email || !this.form.password
    },
  },

  created() {
    if (this.$auth.loggedIn) {
      this.$router.push('/')
    }
  },

  methods: {
    async userLogin() {
      this.formLoading = true

      const res = await this.$auth.loginWith('local', {
        data: this.form,
      })

      this.formLoading = false

      if (res.status !== 200) {
        return
      }

      if (this.prevRoute.name && this.$route.query.redirect !== 'login') {
        this.$router.back()
      } else {
        this.$router.replace('/')
      }

      this.$toast({
        message: 'ログイン成功',
      })
    },
  },
}
