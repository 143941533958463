//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RegisterForm from '@/utils/mixins/RegisterForm'

export default {
  name: 'UserPage',

  mixins: [RegisterForm],

  middleware: ['auth'],

  data() {
    return {
      registerForm: {
        schoolName: this.$auth.user.school_name,
        business: this.$auth.user.business,
        service: this.$auth.user.service,
        studentCount: this.$auth.user.student_count,
        firstName: this.$auth.user.first_name,
        lastName: this.$auth.user.last_name,
        phone: this.$auth.user.phone,
        isSigned: this.$auth.user.is_signed,
      },
      registerFormLoading: false,
      registerFormErrror: '',
      pushForm: {
        isAgreePush: this.$auth.user.is_receive_email,
      },
      pushFormLoading: false,
      pushFormError: '',
      emailForm: {
        email: '',
      },
      emailFormLoading: false,
      emailSubmitDisabled: false,
      emailFormError: '',
      passwordForm: {
        oldPassword: '',
        password: '',
        password2: '',
      },
      passwordFormLoading: false,
      passwordFormError: '',
    }
  },

  computed: {
    registerSubmitDisabled() {
      return this.registerFormLoading
    },
    pushSubmitDisabled() {
      return this.pushFormLoading
    },
    passwordSubmitDisabled() {
      return this.passwordFormLoading
    },
  },

  methods: {
    async updateRegister() {
      const form = {
        school_name: this.registerForm.schoolName,
        business: this.registerForm.business,
        service: this.registerForm.service,
        student_count: this.registerForm.studentCount,
        first_name: this.registerForm.firstName,
        last_name: this.registerForm.lastName,
        phone: this.registerForm.phone,
        is_signed: this.registerForm.isSigned,
      }

      this.registerFormErrror = ''
      this.registerFormLoading = true

      const res = await this.$axios.$put('/user', form)

      this.registerFormLoading = false

      if (res.status !== 200) {
        if (res.message) {
          this.registerFormErrror = res.message
        }

        return
      }

      this.$toast({
        message: '更新しました',
      })
    },
    async updatePush() {
      const form = {
        is_receive_email: this.pushForm.isAgreePush,
      }

      this.pushFormErrror = ''
      this.pushFormLoading = true

      const res = await this.$axios.$put('/user/config', form)

      this.pushFormLoading = false

      if (res.status !== 200) {
        if (res.message) {
          this.pushFormErrror = res.message
        }

        return
      }

      this.$toast({
        message: '更新しました',
      })
    },
    async updateEmail() {
      const form = {
        email: this.emailForm.email,
      }

      this.emailFormError = ''
      this.emailFormLoading = true

      const res = await this.$axios.$post('/send/change-email', form)

      this.emailFormLoading = false

      if (res.status !== 200) {
        if (res.message) {
          this.emailFormError = res.message
        }

        return
      }

      this.$toast({
        message: 'メールを確認してください',
      })

      this.$refs.emailForm.resetFields()
    },
    async updatePassword() {
      if (!this.passwordForm.oldPassword) {
        this.passwordFormError = '現在のパスワードは、必ず指定してください'

        return
      }

      if (this.passwordForm.password !== this.passwordForm.password2) {
        this.passwordFormError =
          '新しいパスワード（確認用） は「新しいパスワード」と同じ内容を入力してください'

        return
      }

      const form = {
        new_password: this.passwordForm.password,
        old_password: this.passwordForm.oldPassword,
      }

      this.passwordFormError = ''
      this.passwordFormLoading = true

      const res = await this.$axios.$put('/user/change-password', form)

      this.passwordFormLoading = false

      if (res.status !== 200) {
        if (res.message) {
          this.passwordFormError = res.message
        }

        return
      }

      this.$toast({
        message: '更新しました',
      })

      this.$refs.passwordForm.resetFields()
    },
  },
}
