//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    thumb: {
      type: String,
      default: undefined,
    },
    thumbStyle: {
      type: Object,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    office: {
      type: String,
      default: '',
    },
    object: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    nameShow() {
      return this.name.trim()
    },
  },
}
