//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    cover: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    detail: {
      type: String,
      default: '',
    },
    guests: {
      type: Array,
      default: () => [],
    },
    content: {
      type: String,
      default: '',
    },
  },
}
