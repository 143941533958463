//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    shareSet: {
      type: Object,
      default: undefined,
    },
  },
}
