//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDate } from '@/plugins/filters'
import { getHead } from '@/utils'

export default {
  name: 'SerialPage',

  layout: 'page',

  async asyncData({ $axios, query, $device }) {
    const res = await $axios.$get('/serial_list', {
      params: {
        page: query.page,
      },
    })

    const {
      serial: { total: pageTotal, current_page: page, per_page: pageSize },
    } = res.data

    let {
      serial: { data: list },
      menu_description: summary,
    } = res.data

    if (summary) {
      summary = summary[0]?.description
    }

    if (list) {
      list = list.map((item) => {
        return {
          title: item.title,
          thumb: item.attachment?.file_url,
          link: `/serial/${item.id}`,
          date: $device.isMobile
            ? formatDate(item.created_at, 'yyyy.MM.dd')
            : '',
        }
      })
    }

    return {
      summary,
      page,
      pageTotal,
      pageSize,
      list,
    }
  },

  data() {
    const { query } = this.$route

    return {
      titleSet: {
        icon: require('~/assets/img/icon-pen@2x.png'),
        title: '連載一覧',
      },
      summary: '',
      page: query.page || 1,
      pageTotal: 0,
      pageSize: 10,
      list: [],
    }
  },

  head() {
    const baseTitle = '連載一覧'

    return getHead({
      title: `${baseTitle}｜コミマグ`,
      description: `${baseTitle}のページです。こんな時どうする？他塾はどうしてる？集客方法やモチベーションUPまでコミマグでは様々な明日の塾経営につながるコンテンツを掲載しています。`,
    })
  },

  computed: {
    showPager() {
      return this.pageTotal > this.pageSize
    },
  },

  watchQuery: ['page'],

  methods: {
    toPage(page) {
      this.$router.push({
        name: 'serial',
        query: {
          page,
        },
      })
    },
  },
}
