//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    thumbStyle() {
      return this.$device.isMobile ? {} : { width: '300px', height: '212px' }
    },
    renderList() {
      const list = []
      const groupCount = 2

      for (let i = 0; i < this.list.length; i += groupCount) {
        list.push(this.list.slice(i, i + groupCount))
      }

      return list
    },
  },
}
