import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=33a7e771&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"
import style0 from "./_id.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BreadcrumbItem: require('/tmp/build_924c4d97/components/breadcrumb/BreadcrumbItem.vue').default,Breadcrumb: require('/tmp/build_924c4d97/components/breadcrumb/Breadcrumb.vue').default,Heading: require('/tmp/build_924c4d97/components/heading/Heading.vue').default,ListAdviser: require('/tmp/build_924c4d97/components/list/ListAdviser.vue').default,Article: require('/tmp/build_924c4d97/components/article/Article.vue').default})
