//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
    tags: {
      type: Array,
      default: () => [],
      required: true,
    },
    isEllipsis: {
      type: Boolean,
      default: false,
    },
  },
}
