//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Screen from '@/utils/mixins/Screen'

export default {
  mixins: [Screen],

  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    showCoverItem() {
      return this.$screen.isSmUp
    },
    coverItem() {
      if (this.showCoverItem) {
        return this.list[0]
      }

      return null
    },
    newsList() {
      const thisList = this.list.map((item) => {
        const { subTitle, ...restItem } = item

        return { ...restItem }
      })

      if (this.showCoverItem) {
        return thisList.slice(1, this.list.length)
      }

      return thisList
    },
    thumbStyle() {
      if (this.$device.isMobile) {
        return { height: '85px' }
      }

      if (this.$screen.isSmOnly) {
        return { width: '285px', height: '190px' }
      }

      return { height: '127px' }
    },
  },
}
