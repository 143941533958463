//
//
//
//
//
//
//
//
//
//
//

import { addCookieTip } from '@/utils/add-cookie-tip'

export default {
  name: 'Page',

  head() {
    const { host = '' } = this.$config
    const { fullPath = '' } = this.$route

    return {
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: `${host}${fullPath}`,
        },
      ],
    }
  },

  mounted() {
    addCookieTip()
  },
}
