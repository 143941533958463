import { render, staticRenderFns } from "./register.vue?vue&type=template&id=70f81fea&"
import script from "./register.vue?vue&type=script&lang=js&"
export * from "./register.vue?vue&type=script&lang=js&"
import style0 from "./register.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BreadcrumbItem: require('/tmp/build_924c4d97/components/breadcrumb/BreadcrumbItem.vue').default,Breadcrumb: require('/tmp/build_924c4d97/components/breadcrumb/Breadcrumb.vue').default,Link: require('/tmp/build_924c4d97/components/link/Link.vue').default,Button: require('/tmp/build_924c4d97/components/button/Button.vue').default,Form: require('/tmp/build_924c4d97/components/form/Form.vue').default,Article: require('/tmp/build_924c4d97/components/article/Article.vue').default,Sidebar: require('/tmp/build_924c4d97/components/Sidebar.vue').default,PageWrap: require('/tmp/build_924c4d97/components/PageWrap.vue').default})
