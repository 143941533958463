//
//
//
//
//
//
//
//
//

import './Heading.scss'

export default {}
