//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PasswordForgetPage',

  data() {
    return {
      form: {
        email: '',
      },
      formLoading: false,
      submitDisabled: false,
    }
  },

  methods: {
    async postChange() {
      const form = {
        email: this.form.email,
      }

      this.formLoading = true

      const res = await this.$axios.$post('/forget-password', form)

      this.formLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: 'メールを確認してください',
      })

      this.$router.replace('/login')
    },
  },
}
