//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'default',
    },
    isLink: {
      type: Boolean,
      default: true,
    },
  },
}
