//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LogoffPage',

  data() {
    return {
      step: 1,
      form: {
        content: '',
      },
      formLoading: false,
    }
  },

  computed: {
    submitDisabled() {
      return this.formLoading
    },
  },

  methods: {
    async postLogoff() {
      const form = {
        leave_reason: this.form.content,
      }

      this.formLoading = true

      const res = await this.$axios.$delete('/user', form)

      this.formLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: 'ログオフの成功',
      })

      await this.$auth.logout()

      this.$router.replace('logoffdone')
    },
  },
}
