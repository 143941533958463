import { render, staticRenderFns } from "./index.vue?vue&type=template&id=79f43475&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BreadcrumbItem: require('/tmp/build_924c4d97/components/breadcrumb/BreadcrumbItem.vue').default,Breadcrumb: require('/tmp/build_924c4d97/components/breadcrumb/Breadcrumb.vue').default,ListHeaderTitle: require('/tmp/build_924c4d97/components/list/ListHeaderTitle.vue').default,Pagination: require('/tmp/build_924c4d97/components/pagination/Pagination.vue').default,ListAdviser: require('/tmp/build_924c4d97/components/list/ListAdviser.vue').default})
