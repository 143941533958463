//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    size: {
      type: String,
      default: 'normal',
    },
    icon: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: '',
    },
  },
}
