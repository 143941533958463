//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TitleMap from '@/utils/mixins/TitleMap'
import { getHead } from '@/utils'
import Pagination from '~/components/pagination/Pagination.vue'

export default {
  name: 'TopicTagsPage',

  components: { Pagination },

  mixins: [TitleMap],

  layout: 'page',

  async asyncData({ $axios, params, query }) {
    const res = await $axios.$get(`/tag/${query.id}/articles`, {
      params: {
        id: query.id,
        page: query.page,
      },
    })

    const {
      tag,
      articles: { total: pageTotal, current_page: page, per_page: pageSize },
    } = res.data

    const { is_no_index_tag: hasNoIndexTag } = tag

    let {
      articles: { data: list },
    } = res.data

    if (list) {
      list = list.map((item) => {
        let author = ''

        if (item.guests?.length > 0) {
          const lastLen = item.guests.length - 1

          author = '監修コミマグアドバイザー：'

          for (let i = 0; i < item.guests.length; i++) {
            author += `${item.guests[i].name}${i < lastLen ? '、' : ''}`
          }
        }

        return {
          title: item.title,
          thumb: item.attachment?.file_url,
          summary: item.description,
          author,
          date: `公開日：${item.publish_date}`,
          link: `/article/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    let tags = []

    if (tag) {
      tags = [
        {
          label: tag.name,
          id: tag.id,
        },
      ]
    }

    return {
      page,
      pageTotal,
      pageSize,
      list,
      tags,
      hasNoIndexTag,
    }
  },

  data() {
    const { query } = this.$route

    return {
      page: query.page || 1,
      pageTotal: 0,
      pageSize: 10,
      list: [],
      tags: [],
      hasNoIndexTag: false,
    }
  },

  head() {
    return getHead({
      title: `${this.tags[0]?.label}｜コミマグ`,
      hasNoIndexTag: this.hasNoIndexTag,
    })
  },

  computed: {
    titleSet() {
      return this.titleMap.tags || {}
    },
    showPager() {
      return this.pageTotal > this.pageSize
    },
    thumbStyle() {
      return this.$device.isMobile ? {} : { width: '198px', height: '140px' }
    },
  },

  watchQuery: ['page'],

  methods: {
    toPage(page) {
      const { query } = this.$route

      this.$router.push({
        name: 'topic-tags',
        query: {
          ...query,
          page,
        },
      })
    },
  },
}
