//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Screen from '@/utils/mixins/Screen'

export default {
  name: 'HomePage',

  mixins: [Screen],

  async asyncData({ $axios }) {
    const res = await $axios.$get('/home')

    let {
      banner: banners,
      advertisement: ads,
      guests,
      new_article: news,
      ranking_article: ranks,
      seminar: slideAds,
      serial: serials,
      commilita: letters,
      cases: examples,
      juku_article: schoolNotes,
      business_article: businessNotes,
      teaching_article: teachingNotes,
      training_article: trainingNotes,
      guests_article: guestNotes,
      communication_article: parentNotes,
      latest_articles: articles,
    } = res.data

    if (banners) {
      banners = banners.map((banner) => {
        const newBanner = {
          img: banner.attachment?.file_url,
          link: banner.href,
        }

        if (banner.title || banner.description) {
          newBanner.cover = {
            btnText: 'PICK UP！',
            title: banner.title,
            subTitle: banner.description,
          }
        }

        return newBanner
      })
    }

    if (ads) {
      ads = ads.map((ad) => {
        return {
          img: ad.attachment?.file_url,
          link: ad.href,
        }
      })
    }

    if (guests) {
      guests = guests.map((slide) => {
        return {
          img: slide.attachment?.file_url,
          link: `/adviser/${slide.id}`,
          name: slide.name,
          position: slide.position,
        }
      })
    }

    if (news) {
      news = news.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.publish_date,
          link: `/article/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    if (ranks) {
      ranks = ranks.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.publish_date,
          link: `/article/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    if (slideAds) {
      slideAds = slideAds.map((item) => {
        return {
          img: item.attachment?.file_url,
          link: `/seminar/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    if (serials) {
      serials = serials.map((item) => {
        return {
          img: item.attachment?.file_url,
          link: `/serial/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    if (letters) {
      letters = letters.map((item) => {
        return {
          img: item.attachment?.file_url,
          link: `/letter/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    if (examples) {
      examples = examples.map((item) => {
        return {
          img: item.attachment?.file_url,
          link: `/example/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    if (schoolNotes) {
      schoolNotes = schoolNotes.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.publish_date,
          link: `/article/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    if (businessNotes) {
      businessNotes = businessNotes.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.publish_date,
          link: `/article/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    if (teachingNotes) {
      teachingNotes = teachingNotes.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.publish_date,
          link: `/article/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    if (trainingNotes) {
      trainingNotes = trainingNotes.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.publish_date,
          link: `/article/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    if (guestNotes) {
      guestNotes = guestNotes.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.publish_date,
          link: `/article/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    if (parentNotes) {
      parentNotes = parentNotes.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.publish_date,
          link: `/article/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    if (articles) {
      articles = articles.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.publish_date,
          link: `/article/${item.id}`,
          mark: item.is_need_vip ? 'vip' : undefined,
        }
      })
    }

    return {
      banners,
      ads,
      guests,
      news,
      ranks,
      slideAds,
      serials,
      letters,
      examples,
      schoolNotes,
      businessNotes,
      teachingNotes,
      trainingNotes,
      guestNotes,
      parentNotes,
      articles,
    }
  },

  data() {
    return {
      banners: [],
      ads: [],
      guests: [],
      news: [],
      showNews: false,
      ranks: [],
      showRanks: false,
      albums: [],
      slideAds: [],
      serials: [],
      letters: [],
      notes: [],
      examples: [],
      schoolNotes: [],
      businessNotes: [],
      teachingNotes: [],
      trainingNotes: [],
      guestNotes: [],
      parentNotes: [],
      articles: [],
    }
  },

  head: {
    title: '明日の塾経営につながるComiru magazine web｜コミマグ',
  },

  computed: {
    homeBannerImgStyle() {
      return this.$device.isMobile ? {} : { width: '374px', height: '264px' }
    },
    homeAdWidth() {
      // ad banner imgs total width
      const adTotalWidth = this.$device.isMobile ? 1008 : 1200

      return `${adTotalWidth}px`
    },
    homeAdImgStyle() {
      return this.$device.isMobile
        ? { width: '324px', height: '108px', margin: '0 auto' }
        : { width: '390px', height: '130px' }
    },
    slidesImgStyle() {
      return this.$device.isMobile ? {} : { height: '190px' }
    },
    sildesPerView() {
      if (this.$device.isMobile) {
        return 2.5
      }

      if (this.$screen.isSmOnly) {
        return 4
      }

      return 5
    },
    adScrollImgStyle() {
      return this.$device.isMobile
        ? { width: '200px', height: '200px' }
        : { width: '240px', height: '240px' }
    },
    adSlideWidth() {
      const slideTotalWidth = this.$device.isMobile ? 872 : 986

      return `${slideTotalWidth}px`
    },
    adSlideImgStyle() {
      return this.$device.isMobile
        ? { width: '284px', height: '200px' }
        : { width: '322px', height: '226px' }
    },
  },

  mounted() {
    const { register_key: registerKey } = this.$route.query

    if (registerKey) {
      this.emailVerification(registerKey)
    }
  },

  methods: {
    async emailVerification(key) {
      const form = {
        key,
      }

      this.formLoading = true

      const res = await this.$axios.$post('/email-verification', form)

      this.formLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: 'メール認証に成功しました',
      })

      await this.$auth.setUserToken(res.data.token)

      this.$router.replace('/')
    },
    checkMax(count, pcMax, mobileMax) {
      if (pcMax && !this.$device.isMobile) {
        return count > pcMax
      }

      if (mobileMax && this.$device.isMobile) {
        return count > mobileMax
      }

      return false
    },
  },
}
