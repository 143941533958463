//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TitleMap from '@/utils/mixins/TitleMap'
import { getHead } from '@/utils'

export default {
  name: 'ArticlePage',

  mixins: [TitleMap],

  async asyncData({ $axios, params }) {
    const res = await $axios.$get(`/article/${params.id}`, {
      params: {
        id: params.id,
      },
    })
    const {
      type,
      post_type: postType,
      title,
      publish_date: date,
      attachment,
      description,
      writer_intor: writerIntro,
      detail,
      content,
      h_video_url: headVideo,
      is_need_vip: needVip,
      is_no_index_tag: hasNoIndexTag,
    } = res.data

    let {
      advertisement: ad,
      tags,
      author,
      guests,
      writers: writer,
      relation_article_list: relates,
      articles_in_same_serial: articlesSerials,
      serials,
    } = res.data
    if (ad) {
      ad = {
        img: ad.file_url,
      }
    }

    if (tags) {
      tags = tags.map((tag) => {
        return {
          label: tag.name,
          to: `/topic/tags?id=${tag.id}`,
        }
      })
    }

    if (author) {
      author = author.name
    }

    if (guests) {
      guests = guests.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.name,
          summary: item.description,
          link: `/adviser/${item.id}`,
        }
      })
    }

    if (writer) {
      writer = [
        {
          thumb: writer.attachment?.file_url,
          title: writer.name,
          summary: writer.description,
          link: `/adviser/${writer.id}`,
        },
      ]
    }

    if (relates) {
      relates = relates.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.publish_date,
          link: `/article/${item.id}`,
        }
      })
    }

    if (articlesSerials) {
      articlesSerials = articlesSerials.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          // date: item.publish_date,
          link: `/article/${item.id}`,
        }
      })
    }

    let showSerials = false

    if (serials) {
      showSerials =
        serials.length > 0 &&
        serials.some(({ articles }) => articles.length > 1)

      serials = serials.map((item) => {
        return {
          articles: item.articles,
          id: item.id,
          link: `/serial/${item.id}`,
        }
      })
    }

    const contentData = {
      cover: attachment?.file_url,
      description,
      detail,
      content,
      guests,
      writers: writer || [],
      writerIntro,
      headVideo,
    }

    return {
      type,
      postType,
      title,
      // TODO: 可优化成typeMap控制输出字段
      ad: type === 5 ? ad : undefined,
      tags,
      author,
      date,
      content: contentData,
      relates,
      needVip,
      articlesSerials,
      serials,
      hasNoIndexTag,
      showSerials,
    }
  },

  data() {
    return {
      type: undefined,
      title: '',
      tags: [],
      date: '',
      content: {},
      author: '',
      relates: [],
      needVip: false,
      articlesSerials: [],
      serials: [],
      hasNoIndexTag: false,
    }
  },

  head() {
    return getHead({
      title: `${this.title}｜コミマグ`,
      description: this.content?.description,
      image: this.content?.cover,
      hasNoIndexTag: this.hasNoIndexTag,
    })
  },

  computed: {
    titleSet() {
      return this.titleMap[this.postType] || {}
    },
    template() {
      switch (this.type) {
        case 1:
          return 'ArticleContent1'
        case 2:
          return 'ArticleContent2'
        case 3:
          return 'ArticleContent3'
        case 4:
          return 'ArticleContent4'
        case 5:
          return 'ArticleContent5'
        case 6:
          return 'ArticleContent6'
        case 7:
          return 'ArticleContent7'
        default:
          return 'div'
      }
    },
    shareSet() {
      return {
        title: this.title,
        url: `${this.$store.state.siteURL}${this.$route.fullPath}`,
      }
    },
    isAllowShow() {
      return !this.needVip || this.$auth.loggedIn
    },
  },
}
