//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    isEllipsis: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    wrapComponent() {
      if (this.to) {
        return 'Link'
      }

      return 'span'
    },
  },
}
