//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ErrorPage',

  data() {
    const { status } = this.$route.query

    return {
      status,
    }
  },

  computed: {
    title() {
      switch (this.status) {
        case '404':
          return '404 Not Found'

        default:
          return 'Error'
      }
    },
  },
}
