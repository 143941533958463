//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getHead } from '@/utils'

export default {
  name: 'SerialDetailPage',

  layout: 'page',

  async asyncData({ $axios, params }) {
    const res = await $axios.$get(`/serial/${params.id}`, {
      params: {
        id: params.id,
      },
    })

    const {
      serial: { title, attachment, description },
    } = res.data

    let {
      serial: { published_articles: list },
      otherSerials: relates,
    } = res.data

    if (list) {
      list = list.map((item) => {
        return {
          title: item.title,
          thumb: item.attachment?.file_url,
          link: `/article/${item.id}`,
        }
      })
    }

    if (relates) {
      relates = relates.map((item) => {
        return {
          title: item.title,
          thumb: item.attachment?.file_url,
          link: `/serial/${item.id}`,
        }
      })
    }

    return {
      title,
      cover: attachment?.file_url,
      description,
      list,
      relates,
    }
  },

  data() {
    return {
      title: '',
      cover: '',
      description: '',
      list: [],
      relates: [],
    }
  },

  head() {
    const baseTitle = `${this.title}｜連載`

    return getHead({
      title: `${baseTitle}｜コミマグ`,
      description: `${baseTitle}のページです。こんな時どうする？他塾はどうしてる？集客方法やモチベーションUPまでコミマグでは様々な明日の塾経営につながるコンテンツを掲載しています。`,
      image: this.cover,
    })
  },
}
