//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    size: {
      type: String,
      default: 'normal',
    },
    icon: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    linkMore: {
      type: String,
      default: undefined,
    },
    linkMoreText: {
      type: String,
      default: 'もっと見る',
    },
  },

  computed: {
    renderSize() {
      if (this.size !== 'normal') {
        return this.size
      }

      return this.$device.isMobile ? 'small' : this.size
    },
    showLinkMore() {
      return this.linkMore && !this.$device.isMobile && this.size === 'normal'
    },
  },
}
