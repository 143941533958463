//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AboutPage',

  computed: {
    mapHeight() {
      return this.$device.isMobile ? 146 : 281
    },
  },
}
