//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data({ $config }) {
    return {
      year: new Date().getFullYear(),
      poperHpHost: $config.poperHpHost,
      securityPolicy: $config.poperHpHost + '/security-policy',
      privacyPolicy: $config.poperHpHost + '/privacy-policy',
      cookiePolicy: $config.poperHpHost + '/cookie-policy',
    }
  },
}
