//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ContactPage',

  data() {
    return {
      form: {
        email: '',
        name: '',
        content: '',
        isAgree: false,
      },
      formLoading: false,
    }
  },

  computed: {
    submitDisabled() {
      return this.formLoading || !this.form.isAgree
    },
  },

  methods: {
    async postMail() {
      const form = {
        email: this.form.email,
        name: this.form.name,
        content: this.form.content,
      }

      this.formLoading = true

      await this.$axios.$post('/contact', form)

      this.formLoading = false

      this.$toast({
        message: '送信済み',
      })

      this.$refs.form.resetFields()
    },
  },
}
