//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    thumb: {
      type: String,
      default: undefined,
    },
    thumbStyle: {
      type: Object,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    author: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: undefined,
    },
    summary: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'default',
    },
    isLink: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    componentWrap() {
      return this.isLink ? 'Link' : 'div'
    },
    renderTags() {
      return this.$device.isMobile ? this.tags.slice(0, 3) : this.tags
    },
  },
}
