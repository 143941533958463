//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isText: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '',
    },
  },

  computed: {
    linkTo() {
      if (!this.$attrs.to) {
        return '/'
      }

      // improve: change url to site route
      return this.$attrs.to.replace(this.$store.state.siteURL, '')
    },
    componentWrap() {
      if (this.target) {
        return 'a'
      }

      return this.linkTo.includes('http') ? 'a' : 'n-link'
    },
  },
}
