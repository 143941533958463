//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      showNav: false,
      sideNavs: [
        {
          text: 'Comiru活用法',
          to: '/topic/10',
        },
        {
          text: 'コミマグアドバイザー',
          to: '/adviser',
        },
        {
          text: 'セミナー',
          to: '/serial',
        },
        {
          text: 'お役立ち資料',
          to: '/letter',
        },
        {
          text: '連載',
          to: '/serial',
        },
        {
          text: 'Comiru導入事例',
          to: '/example',
        },
        {
          text: '記事',
          to: '/topic/7',
        },
        {
          text: '塾経営',
          to: '/topic/1',
        },
        {
          text: '教務',
          to: '/topic/3',
        },
        {
          text: '採用/育成/組織',
          to: '/topic/4',
        },
        {
          text: '集客',
          to: '/topic/5',
        },
        {
          text: '保護者対応',
          to: '/topic/6',
        },
        // {
        //   text: '保護者面談',
        //   to: '#',
        // },
        // {
        //   text: 'その他',
        //   to: '#',
        // },
        {
          text: 'お問い合わせ',
          to: '/contact',
        },
      ],
    }
  },

  methods: {
    throwAnError() {
      throw new Error('TEST!')
    },
    openNav() {
      this.showNav = true
    },
    navTo(to) {
      this.showNav = false

      this.$router.push(to)
    },
    userLogout() {
      this.$auth.logout()

      this.$toast({
        message: 'ログアウトの成功',
      })

      this.$router.replace('/')
    },
  },
}
