//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Sidebar',

  data() {
    return {
      showSeminar: false,
      showNews: false,
      showRanks: false,
    }
  },

  computed: {
    sidebar() {
      return this.$store.state.sidebar.data
    },
    newsThumbStyle() {
      return this.$device.isMobile
        ? { width: '120px', height: '85px' }
        : { width: '99px', height: '70px' }
    },
    seminarThumbStyle() {
      return { height: '212px' }
    },
  },
}
