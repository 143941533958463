//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getHead } from '@/utils'

export default {
  name: 'AdviserPage',

  layout: 'page',

  async asyncData({ $axios, query }) {
    const res = await $axios.$get('/guest_list', {
      params: {
        page: query.page,
      },
    })

    const {
      guest: { total: pageTotal, current_page: page, per_page: pageSize },
    } = res.data

    let {
      guest: { data: list },
      menu_description: summary,
    } = res.data

    if (summary) {
      summary = summary[0]?.description
    }

    if (list) {
      list = list.map((item) => {
        const tags = []

        if (item.sns) {
          if (item.sns.web_page) {
            tags.push({
              label: 'Web',
              to: item.sns.web_page,
            })
          }

          if (item.sns.facebook) {
            tags.push({
              label: 'Facebook',
              to: item.sns.facebook,
            })
          }

          if (item.sns.twitter) {
            tags.push({
              label: 'Twitter',
              to: item.sns.twitter,
            })
          }
        }

        return {
          thumb: item.attachment?.file_url,
          title: item.position,
          author: item.name,
          summary: item.description,
          tags,
          link: `/adviser/${item.id}`,
        }
      })
    }

    return {
      summary,
      page,
      pageTotal,
      pageSize,
      list,
    }
  },

  data() {
    const { query } = this.$route

    return {
      titleSet: {
        icon: require('~/assets/img/icon-teacher-2@2x.png'),
        title: 'コミマグアドバイザー一覧',
      },
      summary: '',
      page: query.page || 1,
      pageTotal: 0,
      pageSize: 10,
      list: [],
      tags: [],
    }
  },

  head() {
    const baseTitle = 'コミマグアドバイザー一覧'

    return getHead({
      title: `${baseTitle}｜コミマグ`,
      description: `${baseTitle}のページです。こんな時どうする？他塾はどうしてる？集客方法やモチベーションUPまでコミマグでは様々な明日の塾経営につながるコンテンツを掲載しています。`,
    })
  },

  computed: {
    showPager() {
      return this.pageTotal > this.pageSize
    },
  },

  watchQuery: ['page'],

  methods: {
    toPage(page) {
      this.$router.push({
        name: 'adviser',
        query: {
          page,
        },
      })
    },
  },
}
