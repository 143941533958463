//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'normal',
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    thumb: {
      type: String,
      default: undefined,
    },
    thumbStyle: {
      type: Object,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    summary: {
      type: String,
      default: '',
    },
    author: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: undefined,
    },
    link: {
      type: String,
      default: undefined,
    },
    mark: {
      type: String,
      default: undefined,
    },
    tags: {
      type: Array,
      default: undefined,
    },
    studentNumberTags: {
      type: Array,
      default: undefined,
    },
    corporationTags: {
      type: Array,
      default: undefined,
    },
  },

  computed: {
    showMark() {
      return this.mark || this.$slots.mark
    },
  },
}
