//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getHead } from '@/utils'

export default {
  name: 'TagsPage',

  async asyncData({ $axios }) {
    const res = await $axios.$get('/tags')

    let tags = res.data

    tags = tags.map((tag) => {
      return {
        label: tag.name,
        to: `/topic/tags?id=${tag.id}`,
      }
    })

    return {
      tags,
    }
  },

  data() {
    return {
      tags: [],
    }
  },

  head() {
    const baseTitle = 'キーワード一覧'

    return getHead({
      title: `${baseTitle}｜コミマグ`,
      description: `${baseTitle}のページです。こんな時どうする？他塾はどうしてる？集客方法やモチベーションUPまでコミマグでは様々な明日の塾経営につながるコンテンツを掲載しています。`,
    })
  },
}
