//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getHead } from '@/utils'

export default {
  name: 'SeminarPage',

  layout: 'page',

  async asyncData({ $axios, query }) {
    const res = await $axios.$get(`/seminar_list`)

    let { menu_description: summary } = res.data

    if (summary) {
      summary = summary[0]?.description
    }

    const inRes = await $axios.$get(`/seminar_on_due`, {
      params: {
        page: query.inPage,
      },
    })

    const {
      total: inPageTotal,
      current_page: inPage,
      per_page: inPageSize,
    } = inRes.data

    let { data: inList } = inRes.data

    if (inList) {
      inList = inList.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.date,
          link: `/seminar/${item.id}`,
        }
      })
    }

    const outRes = await $axios.$get(`/seminar_out_due`, {
      params: {
        page: query.outPage,
      },
    })

    const {
      total: outPageTotal,
      current_page: outPage,
      per_page: outPageSize,
    } = outRes.data

    let { data: outList } = outRes.data

    if (outList) {
      outList = outList.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.date,
          link: `/seminar/${item.id}`,
        }
      })
    }

    return {
      summary,
      inPage,
      inPageTotal,
      inPageSize,
      inList,
      outPage,
      outPageTotal,
      outPageSize,
      outList,
    }
  },

  data() {
    const { query } = this.$route

    return {
      titleSet: {
        icon: require('~/assets/img/icon-student-2@2x.png'),
        title: 'セミナー一覧',
      },
      summary: '',
      inPage: query.inPage || 1,
      inPageTotal: 0,
      inPageSize: 10,
      inList: [],
      outPage: query.outPage || 1,
      outPageTotal: 0,
      outPageSize: 10,
      outList: [],
    }
  },

  head() {
    const baseTitle = 'セミナー一覧'

    return getHead({
      title: `${baseTitle}｜コミマグ`,
      description: `${baseTitle}のページです。こんな時どうする？他塾はどうしてる？集客方法やモチベーションUPまでコミマグでは様々な明日の塾経営につながるコンテンツを掲載しています。`,
    })
  },

  computed: {
    showInPager() {
      return this.inPageTotal > this.inPageSize
    },
    showOutPager() {
      return this.outPageTotal > this.outPageSize
    },
  },

  watchQuery: ['inPage', 'outPage'],

  methods: {
    toInPage(page) {
      this.$router.push({
        name: 'seminar',
        query: {
          inPage: page,
        },
      })
    },
    toOutPage(page) {
      this.$router.push({
        name: 'seminar',
        query: {
          outPage: page,
        },
      })
    },
  },
}
