//
//
//
//
//
//
//

export default {
  props: {
    to: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: 'もっと見る',
    },
  },
}
