//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RegisterForm from '@/utils/mixins/RegisterForm'

export default {
  name: 'RegisterPage',

  mixins: [RegisterForm],

  data() {
    return {
      form: {
        schoolName: '',
        business: '',
        service: '',
        studentCount: '',
        familyName: '',
        name: '',
        email: '',
        phone: '',
        password: '',
        isSigned: '',
        isAgree: false,
      },
      formLoading: false,
      formError: '',
    }
  },

  computed: {
    submitDisabled() {
      return this.formLoading || !this.form.isAgree
    },
  },

  created() {
    if (this.$auth.loggedIn) {
      this.$router.push('/')
    }
  },

  methods: {
    async postRegister() {
      const form = {
        school_name: this.form.schoolName,
        business: this.form.business,
        service: this.form.service,
        student_count: this.form.studentCount,
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        email: this.form.email,
        phone: this.form.phone,
        password: this.form.password,
        is_signed: this.form.isSigned,
      }

      this.formError = ''
      this.formLoading = true

      const res = await this.$axios.$post('/register', form)

      this.formLoading = false

      if (res.status !== 200) {
        if (res.message) {
          this.formError = res.message
        }

        return
      }

      const { token } = res.data

      if (token) {
        await this.$auth.setUserToken(token)
      }

      // this.$toast({
      //   message: '登録成功',
      // })

      this.$router.replace('/registerdone')
    },
  },
}
