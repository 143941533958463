//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
    text: {
      type: String,
      default: undefined,
      required: true,
    },
    to: {
      type: String,
      default: undefined,
    },
    isEllipsis: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    componentWrap() {
      return this.to ? 'Link' : 'div'
    },
  },
}
