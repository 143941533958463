//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'banner',
    },
    banners: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: undefined,
    },
    imgStyle: {
      type: Object,
      default: undefined,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    slidesPerView: {
      type: Number,
      default: 1,
    },
    slidesPerGroup: {
      type: Number,
      default: 1,
    },
    spaceBetween: {
      type: Number,
      default: 0,
    },
    showNavs: {
      type: Boolean,
      default: undefined,
    },
    showPages: {
      type: Boolean,
      default: true,
    },
    showScrollBar: {
      type: Boolean,
      default: false,
    },
    centeredSlides: {
      type: Boolean,
      default: false,
    },
    paginationType: {
      type: String,
      default: '',
    },
    isSquare: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    buttonNextID() {
      return `${this.name}-Next`
    },
    buttonPrevID() {
      return `${this.name}-Prev`
    },
    paginationID() {
      return `${this.name}-Pagination`
    },
    scrollBarID() {
      return `${this.name}-ScrollBar`
    },
    isShowNavs() {
      if (this.showNavs === undefined) {
        return !this.$device.isMobile
      }

      return this.showNavs
    },
    swiperOptions() {
      const options = {
        loop: this.loop,
      }

      if (this.isShowNavs) {
        options.navigation = {
          nextEl: `#${this.buttonNextID}`,
          prevEl: `#${this.buttonPrevID}`,
        }
      }

      if (this.showPages) {
        options.pagination = {
          el: `#${this.paginationID}`,
          clickable: true,
        }
      }

      if (this.slidesPerView) {
        options.slidesPerView = this.slidesPerView
      }

      if (this.slidesPerGroup) {
        options.slidesPerGroup = this.slidesPerGroup
      }

      if (this.spaceBetween) {
        options.spaceBetween = this.spaceBetween
      }

      if (this.showScrollBar) {
        options.scrollbar = {
          el: `#${this.scrollBarID}`,
          hide: false,
          draggable: true,
        }
      }

      if (this.centeredSlides) {
        options.centeredSlides = this.centeredSlides
      }

      return options
    },
    swiperStyle() {
      const style = {}

      if (this.width) {
        style.width = this.width
      }

      return style
    },
    paginationTypeSet() {
      if (this.paginationType) {
        return this.paginationType
      }

      if (this.$device.isMobileOrTablet) {
        return 'flat'
      }

      return 'default'
    },
  },
}
