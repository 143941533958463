//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getHead } from '@/utils'

export default {
  name: 'ExamplePage',

  layout: 'page',

  async asyncData({ $axios, query }) {
    const res = await $axios.$get(`/cases_list`, {
      params: {
        page: query.page,
      },
    })

    const {
      caseses_list: {
        total: pageTotal,
        current_page: page,
        per_page: pageSize,
      },
    } = res.data

    let { data: list } = res.data.caseses_list

    if (list) {
      list = list.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          summary: item.title,
          schoolName: item.corporation_title,
          tags: item.tag_ids.map((tag) => tag.name),
          corporationTags: item.corporation_tag_list.map((tag) => tag.name),
          studentNumberTags: item.student_number_tag
            ? [item.student_number_tag]
            : null,
          link: `/example/${item.id}`,
        }
      })
    }

    return {
      page,
      pageTotal,
      pageSize,
      list,
    }
  },

  data() {
    const { query } = this.$route

    return {
      titleSet: {
        icon: require('~/assets/img/icon-example@2x.png'),
        title: 'Comiru導入事例',
      },
      page: query.page || 1,
      pageTotal: 0,
      pageSize: 10,
      list: [],
    }
  },

  head() {
    const baseTitle = 'Comiru導入事例 記事一覧'

    return getHead({
      title: `${baseTitle}｜コミマグ`,
      description: `${baseTitle}のページです。こんな時どうする？他塾はどうしてる？集客方法やモチベーションUPまでコミマグでは様々な明日の塾経営につながるコンテンツを掲載しています。`,
    })
  },

  computed: {
    showPager() {
      return this.pageTotal > this.pageSize
    },
  },

  watchQuery: ['page'],

  methods: {
    toPage(page) {
      this.$router.push({
        name: 'example',
        query: {
          page,
        },
      })
    },
  },
}
