//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDate } from '@/plugins/filters'
import { getHead } from '@/utils'

export default {
  name: 'AdviserDetailPage',

  layout: 'page',

  async asyncData({ $axios, params }) {
    const res = await $axios.$get(`/guests/${params.id}/seminars`, {
      params: {
        id: params.id,
      },
    })

    const { seminars } = res.data

    let { guest, guestArticles, otherGuests: relates } = res.data
    let interviews = []
    let honorarys = []
    let supervisions = []

    if (seminars) {
      seminars.map((seminar) => {
        switch (seminar.type) {
          case 1:
            interviews.push(seminar)
            break
          case 2:
            honorarys.push(seminar)
            break
          case 3:
            supervisions.push(seminar)
            break

          default:
            break
        }

        return seminar
      })
    }

    if (guest) {
      guest = [guest].map((item) => {
        const tags = []

        if (item.sns) {
          if (item.sns.web_page) {
            tags.push({
              label: 'Web',
              to: item.sns.web_page,
            })
          }

          if (item.sns.facebook) {
            tags.push({
              label: 'Facebook',
              to: item.sns.facebook,
            })
          }

          if (item.sns.twitter) {
            tags.push({
              label: 'Twitter',
              to: item.sns.twitter,
            })
          }
        }

        return {
          enable: item.enable,
          thumb: item.attachment?.file_url,
          title: item.position,
          author: item.name,
          summary: item.description,
          tags,
        }
      })
    }

    if (guestArticles.length > 0) {
      guestArticles = guestArticles.map((item) => {
        const author = guest
          ? `登壇コミマグアドバイザー：${guest[0].author}`
          : ''

        return {
          title: item.title,
          thumb: item.attachment?.file_url,
          summary: item.description,
          author,
          date: item.created_at
            ? `公開日：${formatDate(item.created_at, 'yyyy.MM.dd')}`
            : '',
          link: `/article/${item.id}`,
        }
      })
    }

    if (interviews) {
      interviews = interviews.map((item) => {
        let author = ''

        if (item.guest_names?.length > 0) {
          const lastLen = item.guest_names.length - 1

          author = '登壇コミマグアドバイザー：'

          for (let i = 0; i < item.guest_names.length; i++) {
            author += `${item.guest_names[i]}${i < lastLen ? '、' : ''}`
          }
        }

        return {
          title: item.title,
          thumb: item.attachment?.file_url,
          summary: item.description,
          author,
          date: item.created_at
            ? `公開日：${formatDate(item.created_at, 'yyyy.MM.dd')}`
            : '',
          link: `/seminar/${item.id}`,
        }
      })
    }

    if (honorarys) {
      honorarys = honorarys.map((item) => {
        let author = ''

        if (item.guest_names?.length > 0) {
          const lastLen = item.guest_names.length - 1

          author = '登壇コミマグアドバイザー：'

          for (let i = 0; i < item.guest_names.length; i++) {
            author += `${item.guest_names[i]}${i < lastLen ? '、' : ''}`
          }
        }

        return {
          title: item.title,
          thumb: item.attachment?.file_url,
          summary: item.description,
          author,
          date: item.created_at
            ? `公開日：${formatDate(item.created_at, 'yyyy.MM.dd')}`
            : '',
          link: `/seminar/${item.id}`,
        }
      })
    }

    if (supervisions) {
      supervisions = supervisions.map((item) => {
        let author = ''

        if (item.guest_names?.length > 0) {
          const lastLen = item.guest_names.length - 1

          author = '登壇コミマグアドバイザー：'

          for (let i = 0; i < item.guest_names.length; i++) {
            author += `${item.guest_names[i]}${i < lastLen ? '、' : ''}`
          }
        }

        return {
          title: item.title,
          thumb: item.attachment?.file_url,
          summary: item.description,
          author,
          date: item.created_at
            ? `公開日：${formatDate(item.created_at, 'yyyy.MM.dd')}`
            : '',
          link: `/seminar/${item.id}`,
        }
      })
    }

    if (relates) {
      relates = relates
        .filter((item) => item.enable)
        .map((item) => {
          return {
            thumb: item.attachment?.file_url,
            title: item.position,
            author: item.name,
            summary: item.description,
            link: `/adviser/${item.id}`,
          }
        })
    }

    return {
      guests: [guest],
      guestArticles,
      interviews,
      honorarys,
      supervisions,
      relates,
    }
  },

  data() {
    return {
      guests: [],
      guestArticles: [],
      interviews: [],
      honorarys: [],
      supervisions: [],
      relates: [],
    }
  },

  head() {
    const baseTitle = `${this.guestTitle}｜コミマグアドバイザー`

    return getHead({
      title: `${baseTitle}｜コミマグ`,
      description: `${baseTitle}のページです。こんな時どうする？他塾はどうしてる？集客方法やモチベーションUPまでコミマグでは様々な明日の塾経営につながるコンテンツを掲載しています。`,
    })
  },

  computed: {
    showBreadcrumb() {
      return !this.$device.isMobile && this.guests[0]?.[0]?.enable
    },
    guestTitle() {
      const curGuest = this.guests[0]

      if (!curGuest) {
        return ''
      }

      return curGuest[0]?.author || ''
    },
    thumbStyle() {
      return this.$device.isMobile ? {} : { width: '198px', height: '140px' }
    },
  },
}
