//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getHead } from '@/utils'

export default {
  name: 'ExampleDetailPage',

  layout: 'page',

  async asyncData({ $axios, params }) {
    const res = await $axios.$get(`/cases/${params.id}`, {
      params: {
        id: params.id,
      },
    })

    const {
      case: {
        title,
        date,
        attachment,
        description,
        content,
        corporation_attachment: corporationThumb,
        corporation_title: corporationTitle,
        corporation_name: corporationName,
        corporation_object: corporationObject,
        corporation_office: corporationOffice,
        corporation_position: corporationPosition,
        is_no_index_tag: hasNoIndexTag,
      },
      corporationTags,
    } = res.data

    let {
      case: { tag_ids: tags },
      relationArticles: relates,
    } = res.data

    if (tags) {
      tags = tags.map((tag) => {
        return {
          label: tag.name,
          to: `/topic/tags?id=${tag.id}`,
        }
      })
    }

    let corporation

    if (corporationName) {
      corporation = {
        thumb: corporationThumb?.file_url,
        title: corporationTitle,
        name: corporationName,
        object: corporationObject,
        office: corporationOffice,
        position: corporationPosition,
        tags: corporationTags.map((item) => item.name),
      }
    }

    if (relates) {
      relates = relates.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.publish_date,
          link: `/article/${item.id}`,
        }
      })
    }

    return {
      tags,
      title,
      date,
      cover: attachment?.file_url,
      corporation,
      description,
      content,
      relates,
      hasNoIndexTag,
    }
  },

  data() {
    return {
      tags: [],
      title: '',
      date: '',
      cover: '',
      corporation: undefined,
      description: '',
      content: '',
      relates: [],
      hasNoIndexTag: false,
    }
  },

  head() {
    const baseTitle = this.title

    return getHead({
      title: `${baseTitle}｜コミマグ`,
      description: `${baseTitle}のページです。こんな時どうする？他塾はどうしてる？集客方法やモチベーションUPまでコミマグでは様々な明日の塾経営につながるコンテンツを掲載しています。`,
      hasNoIndexTag: this.hasNoIndexTag,
      image: this.cover,
    })
  },

  computed: {
    shareSet() {
      return {
        title: this.title,
        url: `${this.$store.state.siteURL}${this.$route.fullPath}`,
      }
    },
  },
}
