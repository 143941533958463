//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './Article.scss'

export default {
  props: {
    showLogin: {
      type: Boolean,
      default: false,
    },
    showShare: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: undefined,
    },
    shareSet: {
      type: Object,
      default: () => ({}),
    },
    loginText: {
      type: String,
      default: '会員登録はこちら',
    },
  },
}
