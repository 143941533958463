//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    cover: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    detail: {
      type: String,
      default: '',
    },
  },
}
