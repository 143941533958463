import { render, staticRenderFns } from "./ListNote.vue?vue&type=template&id=be796a46&"
import script from "./ListNote.vue?vue&type=script&lang=js&"
export * from "./ListNote.vue?vue&type=script&lang=js&"
import style0 from "./ListNote.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ListNewsItem: require('/tmp/build_924c4d97/components/list/ListNewsItem.vue').default,ListNews: require('/tmp/build_924c4d97/components/list/ListNews.vue').default})
