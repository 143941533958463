
import { Pagination } from 'element-ui'

export default {
  extends: Pagination,

  props: {
    background: {
      type: Boolean,
      default: true,
    },
  },
}
