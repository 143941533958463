//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getHead } from '@/utils'

export default {
  name: 'LetterDetailPage',

  layout: 'page',

  async asyncData({ $axios, params }) {
    const res = await $axios.$get(`/commilita/${params.id}`, {
      params: {
        id: params.id,
      },
    })

    const { title, attachment, description, detail, agenda, content } = res.data

    return {
      title,
      cover: attachment?.file_url,
      description,
      detail,
      agenda,
      content,
    }
  },

  data() {
    return {
      title: '',
      cover: '',
      description: '',
      detail: '',
      agenda: '',
      content: '',
    }
  },

  head() {
    return getHead({
      title: `${this.title}｜お役立ち資料｜コミマグ`,
      description: this.description,
      image: this.cover,
    })
  },
}
