//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    thumb: {
      type: String,
      default: undefined,
    },
    thumbStyle: {
      type: Object,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: undefined,
    },
    summary: {
      type: String,
      default: '',
    },
  },
}
