//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'EmailChangePage',

  data() {
    const { query } = this.$route

    return {
      key: query.key,
      formLoading: false,
    }
  },

  computed: {
    submitDisabled() {
      return this.formLoading
    },
  },

  methods: {
    async postCancel() {
      const form = {
        key: this.key,
        is_confirm: false,
      }

      this.formLoading = true

      const res = await this.$axios.$put('/change-email', form)

      this.formLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: 'キャンセルされました',
      })

      this.$router.replace('/')
    },
    async postChange() {
      const form = {
        key: this.key,
        is_confirm: true,
      }

      this.formLoading = true

      const res = await this.$axios.$put('/change-email', form)

      this.formLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: '更新しました',
      })

      await this.$auth.logout()

      this.$router.replace('/')
    },
  },
}
